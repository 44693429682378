import React from "react";
import { PriceWrapper } from "../Sections/Konditionen/KonditionenStyled";
import { H1, P } from "../Sections/SectionStyled";
import { SubPageWrapper, H2 } from "./SubPageStyled";

const Konditionen: React.FC = () => (
  <SubPageWrapper>
    <H1>Meine Konditionen - zusammengefasst</H1>
    <P>
      Meine wissenschaftlich fundierten Methoden sind darauf ausgerichtet, so
      effizient wie möglich belastbare Ergebnisse zu erzielen. In vielen Fällen
      sind die gesetzten Ziele nach einer begrenzten Anzahl an Sitzungen
      erreicht.
    </P>
    <P>
      Gerne sprechen wir vor Beginn unserer Zusammenarbeit über den erwartbaren
      Zeit- und Kostenaufwand. Bitte entscheiden Sie sich ganz in Ruhe. Meine
      aktuellen Preise sind:
    </P>
    <PriceWrapper>
      <P>
        Erstgespräch (mind. 50 Minuten): 60,- EUR <br />
        Sitzung 50 Minuten: 85,- EUR
        <br />
        Sitzung 80 Minuten: 105,- EUR
        <span>
          <br />
          -ggf. zzgl. 19% MwSt.
          <br />
          -Psychotherapeutische Leistungen sind gem. §4, Nr.14a UStG von der
          MwSt. befreit <br />
          Detailierte Informationen zu den Konditionen, Durchführung und Zahlung
        </span>
      </P>
    </PriceWrapper>
    <H2>Erstgespräch</H2>
    <P>
      Das Erstgespräch dauert in der Regel 50 Minuten. Wir lernen einander
      kennen und machen uns ein Bild von Ihrem Anliegen und den Zielen unserer
      Zusammenarbeit. Auf dieser Basis besprechen wir das gemeinsame weitere
      Vorgehen: Therapie oder Coaching, Dauer, Kosten und natürlich alle Fragen
      und Wünsche, die Sie haben.
    </P>
    <H2>Sitzungen</H2>
    <P>
      Das Erstgespräch sowie alle weiteren Gespräche finden grundsätzlich in der
      Praxis statt. Bitte beachten Sie dabei das Hygiene-Konzept. Je nach
      Anliegen und Indikation besteht die Möglichkeit, einzelne Gespräche auch
      per Video Call (Zoom, Facetime) durchzuführen. Einzelheiten besprechen wir
      gerne persönlich.
    </P>
    <H2>Schweigepflicht</H2>
    <P>
      Als Heilpraktiker Psychotherapie und Coach bin ich nach § 203, Abs. 1 StGB
      verpflichtet, über alle mir in Ausübung meiner Berufstätigkeit
      anvertrauten und bekannt gewordenen Inhalte zu schweigen. Die
      Schweigepflicht besteht auch gegenüber Familienangehörigen. Die
      Verpflichtung zur Verschwiegenheit entfällt nur bei einer Entbindung durch
      Sie.
    </P>
    <H2>Terminabsagen</H2>
    <P>
      Ich berücksichtige Terminabsagen bis 24 Stunden im Vorfeld zu dem
      geplanten Termin, um eine verlässliche Planung in meiner Praxis zu
      gewährleisten. Bei kurzfristigeren Terminabsagen oder Nichterscheinen wird
      50 % des Honorars für die ausgefallene Sitzung fällig.
    </P>
    <H2>Zahlung</H2>
    <P>
      Jede Sitzung wird in der Regel direkt nach der Sitzung mit EC-Karte
      bezahlt. Alternativ kann das Honorar für ein vorher festgelegte Anzahl von
      Sitzungen nach Erhalt der Rechnung überwiesen werden. Rechnungen für
      Sitzungen nach dem Heilpraktikergesetz werden ohne Mwst. ausgestellt.
      Rechnung für Sitzungen im Rahmen eines Coachings werden mit Mwst.
      ausgestellt. In vielen Fällen können diese Kosten steuerlich geltend
      gemacht werden.
    </P>
    <H2>Kostenübernahme</H2>
    <P>
      Gesetzliche Krankenkassen übernehmen die Kosten für einen Heilpraktiker
      für Psychotherapie in der Regel nicht bzw. nur in Ausnahmefällen: Wenn
      kein anderer Therapieplatz verfügbar ist und Sie von Ihrer Versicherung
      die dafür notwendige Genehmigung erhalten. Für detaillierte Informationen
      wenden Sie sich am besten an Ihre Versicherung.
    </P>
  </SubPageWrapper>
);

export default Konditionen;
